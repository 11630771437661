import type { ButtonProps } from '@shc/ui/dist/components/Button/Button'
import { campaignFilledClasses, campaignOutlinedClasses } from '@style/classes'

export type OldButtonProps =
  | 'primary'
  | 'primary-outline'
  | 'accent'
  | 'accent-outline'
  | 'light'
  | 'light-outline'
  | 'transparent'
  | 'transparent-outline'
  | 'none'

const buttonVariantMap: Record<OldButtonProps, ButtonProps> = {
  primary: {
    color: 'primary',
    variant: 'filled',
  },
  'primary-outline': {
    color: 'primary',
    variant: 'outlined',
  },
  light: {
    color: 'light',
    variant: 'filled',
  },
  'light-outline': {
    color: 'light',
    variant: 'outlined',
  },
  accent: {
    variant: 'filled',
    className: campaignFilledClasses,
  },
  'accent-outline': {
    variant: 'outlined',
    className: campaignOutlinedClasses,
  },
  transparent: {
    color: 'transparent',
    variant: 'filled',
  },
  'transparent-outline': {
    color: 'transparent',
    variant: 'outlined',
  },
  none: {
    variant: 'flat',
  },
}

export const mapButtonVariant = (variant?: keyof typeof buttonVariantMap): ButtonProps => {
  return buttonVariantMap[variant ?? 'none']
}
